import type { UsersPickerQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import DropdownDivider from "~/components/dropdown-divider";
import DropdownLink from "~/components/dropdown-link";
import { useLocationPusher } from "~/components/link";
import NavDropdown from "~/components/nav-dropdown";
import { useFetcherData } from "~/utils/remix";

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  includeAll?: boolean;
  additionalOptions?: [label: string, value: string][];
  redirect?: boolean;
}

export default function UserMenu({
  value,
  onChange,
  includeAll,
  additionalOptions = [],
  redirect
}: Props) {
  const push = useLocationPusher();

  const handleChange = (who: string, event: React.MouseEvent) => {
    event.preventDefault();
    if (redirect) {
      push({ user: who });
    } else {
      onChange?.(who);
    }
  };

  const loader = useLoaderData<{ userMenu?: UsersPickerQuery } | null>();
  const fetcher = useFetcherData<UsersPickerQuery>(
    loader?.userMenu ? undefined : "/resources/users/menu"
  );
  const users = loader?.userMenu?.users || fetcher.data?.users || [];
  const match = users.find((u) => u.id === value);

  return (
    <NavDropdown
      label={
        <>
          Who:{" "}
          {value?.toUpperCase() === "ALL"
            ? "All Staff"
            : match?.fullname ||
              additionalOptions.find(([, _value]) => _value === value)?.[0] ||
              ""}
        </>
      }
    >
      {includeAll && (
        <DropdownLink
          match={value === "ALL"}
          label="All Staff"
          to={(e) => handleChange("ALL", e)}
        />
      )}
      {additionalOptions.map(([_label, _value]) => (
        <DropdownLink
          key={_value}
          match={value === _value}
          label={_label}
          to={(e) => handleChange(_value, e)}
        />
      ))}
      {(includeAll || additionalOptions.length > 0) && <DropdownDivider />}

      {_.sortBy(users, "fullname").map((u) => (
        <DropdownLink
          match={u.id === value}
          key={u.id}
          label={u.fullname}
          to={(e) => handleChange(u.id, e)}
        />
      ))}
    </NavDropdown>
  );
}
